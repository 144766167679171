import React, { useState } from 'react';
import Header from '../component/header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoader(true);

        try {
            localStorage.clear();
            const response = await fetch(`${baseApiUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            setLoader(false);

            if (data.status) {
                toast.success('Login successful');
                localStorage.setItem('userToken', data.token); // Save token
                navigate('/user-dashboard'); // Redirect to home
            } else {
                toast.error(data.message || 'Login failed');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again later.');
            setLoader(false);
        }
    };

    return (
        <>
           <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/alderCoin.png" className="logo" alt="logo" style={{height:"50px"}} />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a
                                                    href="#technology"
                                                >
                                                    Technology
                                                </a>
                                            </li>

                                            <li className="dropdown show-dropdown">
                                                <a
                                                    href="#tokenomics"
                                                >
                                                    Tokenomics
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a
                                                    href="#revenue"
                                                >
                                                    Revenue
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a
                                                    href="#roadmap"
                                                >
                                                    Raodmap
                                                </a>
                                            </li>

                                            <li className="dropdown show-dropdown">
                                                <a
                                                    href="#faq"
                                                >
                                                    Faq
                                                </a>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* header-section end */}
            <section className="pt-120 pb-120 bg9-color">
                <div className="container pt-15 pt-md-20">
                    <div className="row justify-content-center justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="modal-content bg7-color rounded-20 p-3 p-sm-4 p-md-6">
                                <div className="modal-header">
                                    <h5 className="modal-title fs-5" id="exampleModalToggleLabel">
                                        Welcome to Alder Coin
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="apex_section__aside-logintop d-flex align-items-center gap-3">
                                        <span>New to Here ?</span>{" "}
                                        <i className="ti ti-arrows-exchange-2 fs-four p1-color" />
                                        <a
                                            href="/sign_up"
                                            className="d-flex align-items-center gap-3 p1-color"
                                        >
                                            Sign up
                                        </a>
                                    </div>
                                    <div className="apex_section__aside-tabs mt-4">
                                        <div className="singletab mb-5 mb-md-6">
                                            <div className="tabcontents">
                                                <div className="tabitem active">
                                                    <div className="apex_section__forms">
                                                    <form onSubmit={handleLogin}>
                                        <div>
                                            <span>Enter Email</span>
                                            <input
                                                className="br2 mb-3 mb-md-4"
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <span>Enter Password</span>
                                            <input
                                                className="br2 mb-3 mb-md-4"
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <a className="d-block text-end p1-color" href="/forgot-password">
                                            Forgot password
                                        </a>
                                        {loader ? (
                                            <div className="text-center">
                                                <img src="assets/images/loader/loader.webp" style={{ height: "120px" }} alt="loader" />
                                            </div>
                                        ) : (
                                            <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6" type="submit">
                                                Login
                                            </button>
                                        )}
                                    </form>
                                                    </div>
                                                </div>
                                                <div className="tabitem">
                                                    <div className="apex_section__qrcode">
                                                        <img src="assets/images/qrcode.png" alt="rqcode" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-3 mb-md-4">
                                            <hr className="w-100 br2" />
                                            <span className="w-100">or log in with</span>
                                            <hr className="w-100 br2" />
                                            
                                        </div>
                                        <div className="d-flex align-items-center gap-5 gap-md-6 mb-3">
                                            <a
                                                className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                href="javascript:void(0)"
                                            >
                                                <img src="assets/images/icon/google-icon.png" alt="icons" />
                                                Google
                                            </a>
                                            <a
                                                className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                href="javascript:void(0)"
                                            >
                                                <img src="assets/images/icon/apple-icon.png" alt="icons" />
                                                Google
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Alder Coin </h2>
                                </a>
                                <p className="mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
            <ToastContainer />
        </>

    )
}

export default Login