import React from 'react'
import Header from '../component/header'

function Contact_us() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <Header/>
            {/* header-section end */}
            {/*Common hero Section Starts */}
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">
                                    CONTACT US
                                </h1>
                                <p className="roboto wow fadeInUp">
                                    {" "}
                                    Discover our unbeatable pricing plan, offering the perfect balance
                                    of value and features, tailored to meet your unique needs in
                                    Alder Coin.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Common hero Section Ends */}
            {/* Contact INFO Starts */}
            <section className="contact_info pt-120 pb-10 pb-md-18 bg5-color">
                <div className="container">
                    <div className="row gy-5 gy-sm-6">
                        <div className="col-xl-8">
                            <div className="contact_info__form br2 p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                                <span className="fs-ten p1-color mb-4">CONTACT INFO</span>
                                <h2 className="p6-color mb-6 mb-md-8">Get Meeting With us.</h2>
                                <form>
                                    <div className="contact_info__inpuarea mb-6 mb-md-8 bg1-color br2 rounded-20 p-3 p-md-5">
                                        <div className="contact_info__inpuarea-group d-flex align-items-center flex-wrap flex-sm-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                                            <input
                                                className="bg4-color"
                                                type="text"
                                                placeholder="First Name"
                                            />
                                            <input
                                                className="bg4-color"
                                                type="text"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                        <div className="contact_info__inpuarea-group d-flex align-items-center flex-wrap flex-sm-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                                            <input
                                                className="bg4-color"
                                                type="email"
                                                placeholder="Your Email"
                                            />
                                            <input
                                                className="bg4-color"
                                                type="number"
                                                placeholder="Your phone"
                                            />
                                        </div>
                                        <textarea
                                            rows={5}
                                            placeholder="Write something..."
                                            defaultValue={""}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="roboto fw-bold cmn-btn px-5 px-md-6 py-3"
                                    >
                                        Submit Now
                                    </button>
                                </form>
                            </div>
                            
                        </div>
                        <div className="col-xl-4">
                            <div className="contact_info__partcard">
                                <div className="row gy-5 gy-md-6">
                                    <div className="col-sm-6 col-lg-6 col-xl-12">
                                        <div className="contact_info__card br2 px-4 px-sm-6 px-md-8 py-4 py-sm-6 py-md-10 rounded-20 text-center mb-5 mb-md-6 wow fadeInUp">
                                            <div className="contact_info__card-icon d-center bg2-color py-3 py-md-4 px-4 px-md-5 d-inline-block rounded-item mb-5 mb-md-6">
                                                <i className="ti ti-address-book fs-two fw-normal" />
                                            </div>
                                            <a href="javascript:void(0)">
                                                <h4 className="mb-3 mb-md-4">ADDRESS</h4>
                                            </a>
                                            <p className="roboto">The Scottish Queen, 21-24 South St, Sheffield S2 5QX, London, United Kingdom</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 col-xl-12">
                                        <div className="contact_info__card br2 px-4 px-sm-6 px-md-8 py-4 py-sm-6 py-md-10 rounded-20 text-center wow fadeInUp">
                                            <div className="contact_info__card-icon d-center bg2-color py-3 py-md-4 px-4 px-md-5 d-inline-block rounded-item mb-5 mb-md-6">
                                                <i className="ti ti-mail-filled p4-color fs-two fw-normal" />
                                            </div>
                                            <h4 className="mb-3 mb-md-4">Email</h4>
                                            <a href="https://softivus.com/cdn-cgi/l/email-protection#186b6d6868776a6c586c707d757d6a79767d36767d">
                                                <span
                                                    className="__cf_email__"
                                                    data-cfemail="b3c0c6c3c3dcc1c7f3c7dbd6ded6c1d2ddd4d69dddd6"
                                                >
                                                    Support : support@alderblockchain.com
                                                </span>
                                            </a>
                                            <a href="https://softivus.com/cdn-cgi/l/email-protection#dba8aeababb4a9afa8a89bafb3beb6bea9bab5bef5b5be">
                                                <span
                                                    className="__cf_email__"
                                                    data-cfemail="c0a9aea6af80b4a8a5ada5b2a1aea7a5eeaea5b4"
                                                >
                                                   Contact : admin@alderblockchain.com
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="contact_info__maps wow fadeInUp">
                                
                                <iframe
                                    className="rounded-20"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2379.915824528458!2d-1.4620193230552028!3d53.38055557229926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879829da8a395d9%3A0x97e5209e0aea60e5!2sThe%20scottish%20queen!5e0!3m2!1sen!2sin!4v1732966041594!5m2!1sen!2sin"
                                    width={600}
                                    height={700}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact INFO Starts */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Alder Coin </h2>
                                </a>
                                <p className="mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                Welcome to Alder Coin, your gateway to the Web3 community! Our user-friendly platform empowers you to explore and trade Alder Coin alongside popular cryptocurrencies, all while connecting with a vibrant community of enthusiasts.
                                </p>
                                <p >Support : support@alderblockchain.com</p>
                                <p >Contact : admin@alderblockchain.com</p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp mt-5">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                   
                                    <a href="https://www.facebook.com/profile.php?id=61568153151358" target="blank" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/facebook-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                    <a href="https://x.com/AlderCoin" target="blank" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/twitter-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                    <a href="https://www.youtube.com/@aldercoin" target="blank" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/youtube-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                    <a href="https://www.instagram.com/aldercoin/"  target="blank" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/instagram-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="">
                                    <ul className="d-flex flex-column gap-4 gap-md-5 ml-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">About Alder Coin</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="/sign_up">Join Us</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Blog</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="/contact_us">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Contact Validator</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Protection fund</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Security</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">BNB Chain</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Ethereum</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">ERC20</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <span>Head Office :The Scottish Queen, 21-24 South St,
                        Sheffield S2 5QX, London, United Kingdom</span>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            Alder Coin
                                        </a>
                                        All Rights Reserved {" "}
                                        <span className="p4-color fs-five mx-2">|</span> 
                                        <a
                                            href="Javascript:void(0)"
                                            className="p4-color roboto"
                                        >
                                            
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Contact_us