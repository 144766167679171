import React, { useState } from 'react';
import axios from 'axios';

const ChatGPT = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendMessage = async () => {
        if (input.trim() === '') return;

        // Add user's message to the chat
        const newMessages = [...messages, { sender: 'user', text: input }];
        setMessages(newMessages);
        setInput('');
        setLoading(true);

        try {
            // Send request to OpenAI API
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-3.5-turbo', // Or any other GPT model
                    messages: [...newMessages, { role: 'user', content: input }],
                    max_tokens: 150
                },
                {
                    headers: {
                        'Authorization': `Bearer FPslV9P_IhLuDHQv4w9MP3gtqWwQgUQeHuqnuOWfyrJbbkRn284xuS7fN2pl35SYqdIYNycCy6T3BlbkFJlxKDxOHWs6UvILfsNMVUlsiMc_RFsqm9ITbrtzCd5uuz4urDZeYQ0rgVrd2wvXw_niDyIxLIoA`, // Replace with your API key
                        'Content-Type': 'application/json',
                    },
                }
            );

            const botMessage = response.data.choices[0].message.content;
            setMessages([...newMessages, { sender: 'user', text: input }, { sender: 'bot', text: botMessage }]);
        } catch (error) {
            console.error('Error fetching from OpenAI:', error);
            setMessages([...newMessages, { sender: 'bot', text: 'Error: Unable to get a response' }]);
        } finally {
            setLoading(false);
        }
    };

    const chatContainerStyle = {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '300px',
        maxHeight: '400px',
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden'
    };

    const chatBoxStyle = {
        padding: '10px',
        maxHeight: '300px',
        overflowY: 'auto'
    };

    const messageStyle = {
        marginBottom: '10px',
        padding: '5px',
        borderRadius: '10px'
    };

    const userMessageStyle = {
        ...messageStyle,
        backgroundColor: '#d1f1ff',
        textAlign: 'right'
    };

    const botMessageStyle = {
        ...messageStyle,
        backgroundColor: '#e1e1e1',
        textAlign: 'left'
    };

    const chatInputStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: '#fff'
    };

    const inputStyle = {
        width: '80%',
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc'
    };

    const buttonStyle = {
        width: '15%',
        padding: '5px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    const buttonHoverStyle = {
        ...buttonStyle,
        backgroundColor: '#0056b3'
    };

    return (
        <div style={chatContainerStyle}>
            <div style={chatBoxStyle}>
                {messages.map((msg, index) => (
                    <div key={index} style={msg.sender === 'user' ? userMessageStyle : botMessageStyle}>
                        <span>{msg.text}</span>
                    </div>
                ))}
                {loading && <div style={botMessageStyle}>Thinking...</div>}
            </div>
            <div style={chatInputStyle}>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask me anything..."
                    style={inputStyle}
                />
                <button
                    onClick={handleSendMessage}
                    style={buttonStyle}
                    onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatGPT;
