import React, { useEffect, useState } from 'react';
import Web3 from 'web3';

function Auth() {
  const [profileData, setProfileData] = useState(null);
  const loginToken = localStorage.getItem('userToken');
  const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')); // Binance Smart Chain endpoint
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    // Disable right-click context menu
   
    // Fetch profile data
    const fetchProfileData = async () => {
      try {
        if (!loginToken) {
          // Redirect to login if no token is available
          handleLogout();
          return;
        }

        const response = await fetch(`${baseApiUrl}/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${loginToken}`, // Send token in Authorization header
          },
        });

        const result = await response.json();
        //  console.log('User Data', result);
        if (response.ok && result.status) {

          console.log(result);
          // Store profile data in localStorage
          localStorage.setItem('userName', result.USER['name']);
          localStorage.setItem('userImage', result.USER['profile_image']);
          localStorage.setItem('userEmail', result.USER['email']);
          localStorage.setItem('userStatus', result.USER['user_status']);
          localStorage.setItem('joinDate', result.USER['created_at']);
          localStorage.setItem('userTotalteam', result.USER['totalTeam']);
          localStorage.setItem('rank', result.USER['rank']);
          localStorage.setItem('web3address', result.USER['web3address']);
          localStorage.setItem('web3key', result.USER['web3key']);
          localStorage.setItem('packageStatus', result.USER['packageStatus']);
          localStorage.setItem('directTeam', result.USER['directTeam']);
          localStorage.setItem('totalTeam', result.USER['totalTeam']);
          localStorage.setItem('totalIncome', result.USER['totalIncome']); 
          localStorage.setItem('flashIncome', result.USER['flashIncome']); 
          localStorage.setItem('withdrawAmt', result.USER['withdrawAmt']);
          localStorage.setItem('remainAmt', result.USER['remainAmt']);
          localStorage.setItem('royaltIncome', result.USER['royaltIncome']);
          localStorage.setItem('rankReward', result.USER['rankReward']);
          localStorage.setItem('month_income', result.USER['month_income']);
          localStorage.setItem('todayIncome', result.USER['todayIncome']);
          localStorage.setItem('directIncome', result.USER['directIncome']);
          localStorage.setItem('levelIncome', result.USER['levelIncome']);
          localStorage.setItem('rankReward', result.USER['rankReward']);
          localStorage.setItem('profile_image', result.USER['profile_image']);
          localStorage.setItem('totalBusiness', result.USER['totalBusiness']);
          localStorage.setItem('monthBusiness', result.USER['monthBusiness']);
          localStorage.setItem('totalBusiness', result.USER['totalBusiness']);

          setProfileData(result); // Update the state with profile data
        //  console.log(result);


        if(result.USER['packageStatus'] == 'active'){
          localStorage.setItem('userUserId', result.USER['user_id']);

        }else{
          localStorage.setItem('userUserId', '000000');
        }

          if (!result.USER['web3key']) {
            //   console.log('Creating Wallet Address');
            const account = web3.eth.accounts.create();
            const address = account.address;
            const privateKey = account.privateKey;

            await addWallet(address, privateKey); // Ensure to await
          }
        } else {
          // If the response is not OK or status is false, clear local data and redirect
          handleLogout();
        }
      } catch (error) {
     //   console.error('Fetch error:', error);
        // On error, clear local data and redirect
        handleLogout();
      }
    };

    fetchProfileData();


    const disableRightClick = (e) => {
      e.preventDefault(); // Prevent the default right-click behavior
    };

    // Add the event listener when the component mounts
    document.addEventListener('contextmenu', disableRightClick);


    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
    };



    if(localStorage.getItem('userEmail') == ""){
      window.location.href ="";
    }
    
  }, [loginToken]);

  // Function to clear local data and redirect to login
  const handleLogout = () => {
     localStorage.clear();
     window.location.href = "/login";
  };

  const addWallet = async (walletweb3Address, walletkey) => {
    try {
      const response = await fetch(`${baseApiUrl}/update/wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${loginToken}` // Send token in the Authorization header
        },
        body: JSON.stringify({
          web3wallet: walletweb3Address,
          web3key: walletkey
        })
      });

      const data = await response.json();
      
      if (data.status) {
       // console.log('Wallet added successfully:', data);
        // Handle success - you can show a success message or redirect the user
      } else {
      //  console.error('Error adding wallet:', data.message);
        // Handle error - you can show an error message
      }
    } catch (error) {
   //   console.error('Error:', error);
      // Handle network errors
    }
  };

  return (
    <>
      {/* You can render profile data if needed */}
    </>
  );
}

export default Auth;
